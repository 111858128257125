
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import {
  dateToDateString,
  dateToTime,
  formatInputMoney
} from "@/app/infrastructures/misc/Utils";
import { CommodityController } from "@/app/ui/controllers/CommodityController";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.modalSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      CommodityController.setModalSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  isDisabled = true;
  disabledButton = true;
  openSurchangeApplicable = false;
  openDocumentSurcharge = false;
  openStatusCommodity = false;
  isEditTerminated = false;
  answer = false;
  newPath = "";
  minPrice = "0";

  getDetailCommodity(id: any) {
    return CommodityController.getCommodityDetail({ id });
  }

  fetchDetailCommodity() {
    this.getDetailCommodity(this.$route.params.id);
  }

  get detailCommodity() {
    return CommodityController.commodity;
  }

  get minimumPriceView() {
    this.minPrice = CommodityController.minimumPriceView;
    return CommodityController.minimumPriceView;
  }

  get isLoading() {
    return CommodityController.isLoadingDetail;
  }

  get modalSuccess() {
    return CommodityController.modalSuccess;
  }

  get messageSuccess() {
    return CommodityController.messageSuccess;
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  goBack() {
    this.$router.push(`/master-data/commodity/${this.$route.params.id}`);
  }

  isSurchangeApplicable() {
    return CommodityController.isSurchangeApplicable;
  }

  isDocumentSurcharge() {
    return CommodityController.isDocumentSurcharge;
  }

  isStatusCommodity() {
    return CommodityController.isStatusCommodity;
  }

  onInputFormatRupiah() {
    if (/^0/.test(this.minPrice)) {
      this.minPrice = this.minPrice.replace(/^0/, "");
    } else {
      this.minPrice = this.minPrice.replace(/[\D]+/g, "");
      this.minPrice = formatInputMoney(this.minPrice);
      this.setMinimumPrice(this.minPrice);
    }
  }

  setMinimumPrice(val: string) {
    CommodityController.setMinimumPriceView(val);
  }

  isDisabledButton() {
    this.disabledButton = this.minimumPriceView === "";
  }

  changeSurchangeApplicable() {
    CommodityController.setSurchargeApplicable(!this.isSurchangeApplicable());
    this.onCloseSurchangeApplicable();
  }

  changeDocumentSurcharge() {
    CommodityController.setDocumentSurcharge(!this.isDocumentSurcharge());
    this.onCloseDocumentSurcharge();
  }

  changeStatusCommodity() {
    CommodityController.setStatusCommodity(!this.isStatusCommodity());
    this.onCloseStatusCommodity();
  }

  replaceComma(str: string) {
    return str.replace(/,/g, "");
  }

  editCommodity() {
    CommodityController.editCommodity({
      commodity_id: this.detailCommodity.commodity_id,
      description: String(this.detailCommodity.description),
      hs_code: this.detailCommodity.hs_code,
      surcharge_applicable: this.isSurchangeApplicable() ? "yes" : "no",
      document_surcharge: this.isDocumentSurcharge() ? "yes" : "no",
      commodity_service_type: String(
        this.detailCommodity.commodity_service_type
      ),
      min_price: Number(this.replaceComma(this.minPrice)),
      status: this.isStatusCommodity() ? "active" : "inactive"
    });
    this.closeModalSave();
  }

  onOpenSurchangeApplicable() {
    if (this.isSurchangeApplicable() === true) {
      this.openSurchangeApplicable = !this.openSurchangeApplicable;
    } else {
      this.openSurchangeApplicable = false;
      CommodityController.setSurchargeApplicable(true);
    }
  }

  onCloseSurchangeApplicable() {
    this.openSurchangeApplicable = false;
  }

  onOpenStatusCommodity() {
    if (this.isStatusCommodity() === true) {
      this.openStatusCommodity = !this.openStatusCommodity;
    } else {
      this.openStatusCommodity = false;
      CommodityController.setStatusCommodity(true);
    }
  }

  onCloseStatusCommodity() {
    this.openStatusCommodity = false;
  }

  onOpenDocumentSurcharge() {
    if (this.isDocumentSurcharge() === true) {
      this.openDocumentSurcharge = !this.openDocumentSurcharge;
    } else {
      this.openDocumentSurcharge = false;
      CommodityController.setDocumentSurcharge(true);
    }
  }

  onCloseDocumentSurcharge() {
    this.openDocumentSurcharge = false;
  }

  onCloseModalSuccess() {
    this.$router.push(`/master-data/commodity/${this.$route.params.id}`);
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  openSave() {
    return CommodityController.modalSave;
  }

  openModalSave() {
    CommodityController.setModalSave(true);
  }

  closeModalSave() {
    CommodityController.setModalSave(false);
  }

  mounted() {
    this.getDetailCommodity(this.$route.params.id);
  }
}
